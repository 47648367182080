.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.filepond--wrapper {
  width: 87%;
  padding-top: 5px;
}

body {
  margin-top: 60%;
  max-width: 100%;
  margin: auto;
  align-content: center;
  background-color: #f8f9fa
}

input {
  margin: 2px;
}

.form-signin .form-control {
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  box-sizing: border-box;
}

.form-control {
  position: relative;
  margin-bottom: 15px;
  display: inline-block;
  width: 65%;
  height: calc(1.5em + .75rem + 2px);
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control_Select {
  flex-grow: 100;
  position: relative;
  margin-bottom: 15px;
  display: inline-block;
  width: 65%;
  height: 42px;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.form-control:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0,123,255,.25);
}

.form-wrapper {
  position: relative;
  background-color: white;
  width: 90%;
  padding-top: 40px;
  margin-top: 10px;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  box-shadow: 0 2px 2px rgba(0,0,0,.3);
  min-height: 300px;
  height: 100%;
  display: block;
  overflow: hidden;
  margin: auto;
}

.formLabel {
  align-self: baseline;
  display: block;
  text-align: left;
  padding-left: 17%;
}

.submitButton {
  margin-bottom: 27px;
  background-color: #007bff;
  border-color: #007bff;
  width: 65%;
  color: white;
  height: 50px;
  border: none;
  font-size: 16px;
  line-height: 16px;
  border-radius: 5px;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
  transition: .5s;
}

.submitButton:hover {
  background-color: #0062cc;
}

#infoWrapper {
  align-items: center;
}

.error-message {
  align-self: baseline;
  text-align: center;
  font-size: 18px;
  margin-left: 17%;
  padding-top: 9px;
  width: 65%;
}

.disclaimer {
  position: relative;
  left: 0px;
  padding: 0 10.2% 0 6.8%;
  color: #ff2600;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
}

.disclaimer-head {
  font-weight: bold;
}

.navbarSpecial {
  color: #222 !important;
  font-weight: 600;
}

select {
  cursor: pointer;
}

.form-group {
  width: 100%;
}